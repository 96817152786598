import { Component } from "react"
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI


    
 
class RecomendadosCmp extends Component {    

    render() {
        const {            
            titleIcon=<span><strong>TÉRMINOS Y CONDICIONES</strong><br/>Programa Recomienda a tus familiares y amigos</span>,
            txtTitle=<span><strong>Acerca de AFI</strong><br/><br/>Apoyo Financiero, Inc. ("AFI") es una compañía prestamista financiera en el estado de California con licencia del California Department of Business Oversight (DBO Licencia No. 6054790) de conformidad con la Ley de Financiamiento de California, y está autorizada para otorgar préstamos al consumidor de conformidad con el California Pilot Program for Increased Access to Responsible Small Dollar Loans; y ha sido reconocida como una Community Development Financial Institutions - CDFI por el Departamento del Tesoro de los EE. UU.<hr/></span>,
            txtContent=<span><strong>Programa Recomienda a familiares y amigos</strong><br/><br/>AFI ha establecido el Programa Recomienda a tus familiares y amigos (el "Programa") bajo el cual los clientes de préstamos existentes de AFI tienen la oportunidad de recomendar a nuevos solicitantes de préstamos a AFI y recibir una comisión por recomendación en forma de tarjeta de regalo, pago en efectivo, crédito a un pago programado de préstamo u otra cosa de valor, según lo determinado por AFI a su sola discreción, si un préstamo es aprobado y otorgado por AFI a un solicitante de préstamo (incluido un co-prestatario o un co-firmante de un préstamo otorgado a un solicitante) que es referido a AFI por un cliente de préstamo participante bajo los términos y condiciones del Programa. Solo se otorgará una (1) comisión por recomendación por cada préstamo otorgado a los solicitantes calificados. La cantidad o el valor de cada comisión por recomendación será determinada y anunciada por AFI de vez en cuando en el sitio web de AFI o a través de la aplicación móvil de AFI (como se define a continuación).<hr/></span>,
            txtTitleEmp='',
            txtContentValors=<span><strong>Descripción del Programa</strong><br/>Según el Programa, un cliente existente de AFI calificado puede registrar con AFI los nombres y otra información de identificación requerida de posibles solicitantes de préstamos nuevos (incluidos amigos, asociados, compañeros de trabajo, miembros de la familia y otros conocidos) que el cliente participante crea que pueden estar interesados en obtener un préstamo de consumo de AFI y que son solicitantes de préstamos elegibles bajo el Programa. Solo aquellos clientes existentes de AFI que sean elegibles para participar en el Programa tendrán derecho a recibir una comisión por recomendación.<hr/></span>,
            txtContentOne=<span><strong>Términos y Condiciones</strong><br/><br/>Este documento establece o hace referencia a los términos, condiciones y limitaciones del Programa ("Términos y Condiciones") los cuales AFI puede enmendar, modificar, complementar o cancelar en cualquier momento. El acceso y la información publicada o disponible a través del sitio web de AFI para el Programa también se rigen por términos y condiciones adicionales de la aplicación móvil patentada de AFI (la "Aplicación Móvil AFI") y la Política de Privacidad de AFI. AFI puede realizar cambios y adiciones a los Términos y Condiciones, incluida cualquier suspensión, modificación o finalización del Programa, en cualquier momento tras la publicación por parte de AFI de dicho cambio o finalización en su sitio web y entrará en vigencia según lo dispuesto en dicha publicación (o si no se menciona una fecha y hora efectiva en dicha publicación, entonces en el momento en que se realice dicha publicación), y se aplicará a todos los solicitantes de préstamos que hayan sido referidos o registrados a partir de dicha fecha y hora de vigencia. Cada cliente de préstamo participante debe revisar y aceptar estar sujeto a los Términos y Condiciones del Programa vigentes en el momento de cada recomendación. Cualquier disputa entre AFI y cualquier cliente de préstamo de AFI que surja del Programa o de estos Términos y Condiciones se resolverá de la manera y de conformidad con las disposiciones de los Términos de uso de AFI que se encuentran en su sitio web (arriba) y los registros de AFI con respecto a las recomendaciones de solicitantes de préstamos, las calificaciones de los solicitantes de préstamos, los préstamos otorgados a los solicitantes referidos a AFI y los pagos de las comisiones por recomendación de conformidad con el Programa, serán concluyentes y vinculantes para todos los clientes participantes.<hr/></span>,
            txtContentTwo=<span><strong>Clientes de Préstamo Participantes</strong><br/><br/>Cada persona que desee participar en el Programa y recomendar a los solicitantes de préstamo calificados a AFI debe ser un cliente con un préstamo existente en AFI en buen estado, según lo determine AFI a su exclusivo criterio, y cuyos pagos de préstamos estén vigentes (no vencidos) al momento que se realice cada recomendación de nuevo solicitante. Un cliente de préstamo participante no debe ser un prestamista financiero, corredor o intermediario autorizado o calificado como tal según la Ley de Financiamiento de California o cualquier otra ley de licencias.<hr/></span>,
            txtContentThree=<span><strong>Solicitantes de Préstamo Calificados</strong><br/><br/>Bajo el Programa, cada solicitante de préstamo registrado debe solicitar y calificar para un nuevo préstamo al consumidor por parte de AFI en una cantidad y de un tipo de préstamo que AFI ofrece o pone a disposición de los consumidores, y no debe ser un cliente de préstamo existente o anterior de AFI. Cada solicitante de préstamo debe tener al menos 18 años de edad y ser residente de California al momento de enviar su solicitud de préstamo a AFI. La decisión de si un solicitante de préstamo registrado está calificado para un préstamo, y todas las decisiones con respecto a los términos y condiciones de dicho préstamo, serán tomadas por AFI a su exclusivo criterio.<hr/></span>,
            txtContentFour=<span><strong>Confidencialidad</strong><br/><br/>Las solicitudes de préstamo recibidas de los solicitantes de préstamos registrados son confidenciales, y AFI no tiene la obligación de notificar o informar a un cliente de préstamo participante de la recepción de una solicitud de préstamo de un solicitante de préstamo registrado o de cualquier aprobación, desaprobación u otra acción tomada por AFI en respuesta a una solicitud de préstamo recibida de un solicitante de préstamo registrado. Sin embargo, AFI puede notificar a cada cliente de préstamo participante del recibo de una solicitud de préstamo de un solicitante de préstamo registrado recomendado por dicho cliente.</span>,
            txtHistoryOne=<span><strong>Procedimientos del Programa</strong><br/><br/></span>,
            txtHistoryTwo=<span><strong>A. Registro de Cliente Recomendante.</strong><br/><br/>Cada cliente de préstamo de AFI elegible que desee participar en el Programa debe registrarse como cliente recomendante en la Aplicación Móvil de AFI.<hr/></span>,
            txtHistory2014=<span><strong>B. Registro de Solicitantes de Préstamos</strong><br/><br/>Cada cliente de préstamo participante debe registrar, a través de la Aplicación móvil de AFI, el nombre completo, la dirección de residencia y otra información de identificación requerida de cada solicitante de préstamo propuesto recomendado a AFI por el cliente de préstamo bajo el Programa. AFI puede, pero no está obligado a contactar a cada solicitante de préstamo registrado o al cliente de préstamo de AFI recomendante, o tomar cualquier otra medida para solicitar o facilitar la presentación de una solicitud de préstamo recibida de cualquier posible solicitante.<hr/></span>,
            txtHistory2015=<span><strong>C. Número Limitado de Préstamos Financiados; Sin Duplicación u Obligación de Prestar.</strong><br/><br/>El número total de préstamos otorgados por AFI a posibles solicitantes de préstamos que son recomendados a AFI por un cliente participante (incluido cualquier co-prestatario con un cliente) y que califican para el pago de comisiones por recomendación bajo el Programa no excederá de 12 préstamos en un periodo de 12 meses. AFI no tendrá obligación con los clientes participantes a aceptar, procesar ni aprobar las solicitudes de préstamos de los solicitantes de préstamos recomendados por los clientes participantes, y cada solicitante de préstamo elegible recomendado puede registrarse solo una vez y no debe haberse registrado previamente como un posible solicitante. Si un solicitante de préstamo elegible es recomendado por más de un cliente participante, entonces solo el primer cliente participante en recomendar y registrar al solicitante del préstamo será elegible para una comisión por recomendación para ese solicitante.<hr/></span>,
            txtHistory2016=<span>
                                <strong>D. Solicitud de Préstamo Completada; Cierre y Financiación de Préstamo; Informe a la Fuente de Recomendación</strong>
                                <br/><br/>
                                <ol>
                                    <li>El solicitante de préstamo registrado debe presentar una solicitud de préstamo completa dentro de los 30 días posteriores al registro del solicitante por parte del cliente participante recomendante, ya sea en persona, por teléfono o a través de la aplicación en línea.<br/><br/></li>
                                    <li>AFI puede, pero no está obligado a, notificar al solicitante del préstamo sobre el registro de su (s) nombre (s) bajo el Programa y obtener el consentimiento del solicitante para que AFI notifique al cliente referente la identidad del cliente recomendado.<br/><br/></li>
                                    <li>Se le pedirá al solicitante que confirme la recomendación y autorice que AFI notifique al cliente recomendante (participante) que se recibió la solicitud de préstamo y, después de que AFI haya tomado medidas en la solicitud, si el préstamo solicitado se realizó o no.<br/><br/></li>
                                    <li> Las medidas tomadas por AFI o el solicitante del préstamo en cualquier solicitud de préstamo serán completamente discrecionales por parte de AFI y, si el solicitante del préstamo lo aprueba, pueden divulgarse al cliente recomendante; sin embargo, el cliente recomendante NO SERÁ informado de la(s) razón(es) por la(s) cual(es) un préstamo no se aprueba o de hecho no se otorga, ni del monto o los términos de ningún préstamo otorgado.<br/><br/></li>
                                    <li>Toda la información personal y financiera contenida en la solicitud de préstamo o recibida por AFI de otras fuentes en relación con la solicitud permanecerá confidencial, a excepción de la información que es de conocimiento general, o de otra manera fácilmente disponible, para el público en general.Las medidas tomadas por AFI o el solicitante del préstamo en cualquier solicitud de préstamo serán completamente discrecionales por parte de AFI y, si el solicitante del préstamo lo aprueba, pueden divulgarse al cliente recomendante; sin embargo, el cliente recomendante NO SERÁ informado de la(s) razón(es) por la(s) cual(es) un préstamo no se aprueba o de hecho no se otorga, ni del monto o los términos de ningún préstamo otorgado.<br/><br/></li>
                                    <li>El préstamo propuesto, si se aprueba, debe estar completamente documentado y los fondos del préstamo desembolsados a el(los) prestatario(s) a más tardar 30 días calendario después de la aprobación del préstamo por parte de AFI, si corresponde.</li>
                                </ol>
                                </span>
            
            
                    } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:'#698d21'}}>{titleIcon}</strong>                        
                        </Typography>
                        <Typography component='h3' variant="h6" >
                            <strong style={{color:'#e66728'}}>{txtTitleEmp}</strong>                        
                        </Typography>
                        
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtTitle}                       
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent}                        
                    </Typography>
                    <br/>
                     <Typography component='subtitle' variant="subtitle" >
                        {txtContentValors}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentOne}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentTwo}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentThree}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentFour}                        
                    </Typography>
                    <br/>  
                    </CardElevation>
                    <br/><br/>
                    <CardElevation>
                    <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h4' variant="h5" >
                        <strong style={{color:'#698d21'}}>{txtHistoryOne}</strong>                        
                        </Typography>
                        <Typography component='subtitle' variant="subtitle" >
                        {txtHistoryTwo}   
                        <br/>                    
                    </Typography>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtHistory2014}                       
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtHistory2015}                       
                    </Typography>
                    <br/>                    
                    <Typography component='subtitle' variant="subtitle" >
                        {txtHistory2016}                       
                    </Typography>
                    <br/>
                    
                        
                    </Grid>
                </Grid>
                    </CardElevation>
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

RecomendadosCmp.propTypes = {      
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string, 
    txtContent: PropTypes.string, 
    txtTitleEmp: PropTypes.string,
    txtContentValors: PropTypes.string,
    txtContentOne: PropTypes.string, 
    txtContentTwo: PropTypes.string, 
    txtHistoryOne: PropTypes.string, 
    txtHistoryTwo: PropTypes.string, 
    txtHistory2014: PropTypes.string, 
    txtHistory2015: PropTypes.string, 
    txtHistory2016: PropTypes.string, 
    
}

export default withThemeProps(RecomendadosCmp, 'VDPNRecomendados')






